import * as React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import SEO from "../components/seo"


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const IndexCover = data.file.childImageSharp.fluid
  

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      

        <div className="row-start-1 row-end-4 items-start self-end mb-30 ml-10">
        
        <div className="text-12vh leading-none font-serif uppercase text-background" >
                    <div className="relative project-item z-30">
                      <div className="w-8 border-b-2 border-background"></div>
                      <Link to="/selected" itemProp="url">
                          <span itemProp="headline">Selected</span>
                        </Link>
                    </div>    
              </div>  

          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug
            const CoverPath = post.frontmatter.coverImage.childImageSharp.fluid
            
            return (
              <>

              <div className="text-12vh leading-none font-serif uppercase text-background" key={post.fields.slug}>
                    <div className="relative project-item z-30">
                      <div className="w-8 border-b-2 border-background"></div>
                      <Link to={post.fields.slug} itemProp="url" data-img={CoverPath}>
                          <span itemProp="headline">{title}</span>
                        </Link>
                    </div>
                    <div className="project-item-cover absolute right-0 top-40 z-20 w-60 h-80 md:w-50v md:h-70v hidden">
                        <BackgroundImage
                          Tag="section"
                          className='w-60 h-80 md:w-50v md:h-70v'
                          fluid={CoverPath}
                          backgroundColor={`#040e18`}
                        >
                        </BackgroundImage>
                    </div>
              </div>  
            </>
          )
        })}
        {/* Index Cover Image */}
        <div className="index-cover absolute right-0 top-40 z-0 w-60 h-80 md:w-50v md:h-70v">
          {/* <img src={indexCover} alt="Logo" /> */}
          <BackgroundImage
            Tag="section"
            className='w-60 h-80 md:w-50v md:h-70v'
            fluid={IndexCover}
            backgroundColor={`#040e18`}
          >
          </BackgroundImage>
        </div>

      </div>
      
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {order: DESC, fields: frontmatter___date}
    filter: {frontmatter: {featured: {eq: true}}, fileAbsolutePath: {regex: "/content/projects/"}}
      ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featured
          coverImage {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
              gatsbyImageData(width: 1920, placeholder: TRACED_SVG, formats: WEBP)
            }
            absolutePath
          }
        }
      }
    }
    file(relativePath: { eq: "AlexandraHome.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
